import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  Box,
  Tab,
} from "@mui/material";
import { AuthModalHeader } from "./AuthModalHeader";
import { useTranslation } from "react-i18next";
import { materialStyles, styles } from "./styles";
import { useCallback, useMemo, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { OrBlock } from "./OrBlock";
import { SocialButtons } from "./SocialButtons";
import { ChangeAuthTypeBlock } from "./ChangeAuthTypeBlock";
import { IAuthModalType } from "..";
import { useFormik } from "formik";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { loginUser } from "../../../../store/thunks/user";
import {
  getMaskLength,
  plusOnStart,
  validateEmail,
  validatePhone,
} from "../../../../utils/validate";
import { useToast } from "rc-toastr";
import { IAuthType } from "./RegistrationForm";
import { setPhoneParams } from "../../../../store/slices/registration";
import { ICountriesType } from "../../../../constants/Countries";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PhoneCodesModal } from "./PhoneCodesModal";

type ILoginForm = {
  onChangeAuthModalType: (type: IAuthModalType) => void;
  onClose: () => void;
};

type IFormValues = {
  email: string;
  phone: string;
  password: string;
};

export const LoginForm: React.FC<ILoginForm> = ({
  onChangeAuthModalType,
  onClose,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const { regUser } = useAppSelector((state) => state.regUser);
  const { toast } = useToast();

  const [tabValue, setTabValue] = useState<IAuthType>("EMAIL");

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    async (details: IFormValues) => {
      const isCustomPhone = plusOnStart(details.phone);

      const parsedPhone = isCustomPhone
        ? details.phone
        : `${regUser.phoneParams.code}${details.phone}`;

      const isValidPhoneValue = validatePhone(parsedPhone);
      const isValidEmailValue = validateEmail(details.email);

      try {
        switch (true) {
          case Boolean(details.phone):
            if (!isValidPhoneValue) {
              toast.error(t("auth_modal.you_have_entered_incorrect_phone"));
              return;
            }
            await dispatch(
              loginUser({
                phone: parsedPhone,
                password: details.password,
              }),
            );
            onClose();
            break;
          case Boolean(details.email):
            if (!isValidEmailValue) {
              toast.error(t("auth_modal.you_have_entered_incorrect_email"));
              return;
            }
            await dispatch(
              loginUser({
                email: details.email,
                password: details.password,
              }),
            );
            onClose();
            break;
          default:
            toast.error(t("auth_modal.email_or_phone_error"));
            break;
        }
      } catch (error) {
        console.error("Error while [onSubmit]", error);
      }
    },
    [dispatch, onClose, regUser.phoneParams.code, t, toast],
  );

  const { values, handleChange, handleSubmit, isValid, errors, setFieldValue } =
    useFormik({
      onSubmit,
      initialValues: {
        email: "",
        phone: "",
        password: "",
      },
      validate: (values) => {
        return _.pickBy({
          email: tabValue === "EMAIL" && !_.trim(values.email),
          phone: tabValue === "PHONE" && !_.trim(values.phone),
          password: !_.trim(values.password),
        });
      },
    });

  const onShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const onChangeModalType = useCallback(() => {
    onChangeAuthModalType("SIGNUP");
  }, [onChangeAuthModalType]);

  const onForgotPassword = useCallback(() => {
    onChangeAuthModalType("FORGOT_PASSWORD");
  }, [onChangeAuthModalType]);

  const handleTabChange = useCallback(
    (event: any, value: IAuthType) => {
      setFieldValue("email", "");
      setFieldValue("phone", "");
      setTabValue(value);
    },
    [setFieldValue],
  );

  const onChoosePhoneParams = useCallback(
    (item: ICountriesType) => {
      dispatch(setPhoneParams(item));
    },
    [dispatch],
  );

  const onSocialAuthSuccess = useCallback(
    (isNew: boolean) => {
      if (isNew) {
        onChangeAuthModalType("SET_USER_NICKNAME");
        return;
      }
      onClose();
    },
    [onChangeAuthModalType, onClose],
  );

  const isShowPhoneCodes = useMemo(
    () => !plusOnStart(values.phone),
    [values.phone],
  );

  const phoneMaxLength = useMemo(
    () =>
      isShowPhoneCodes
        ? getMaskLength(regUser.phoneParams.maskPlaceholder)
        : undefined,
    [isShowPhoneCodes, regUser.phoneParams.maskPlaceholder],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid sx={materialStyles.innerAuthModalBox}>
        <AuthModalHeader title={t("auth_modal.hey_welcome_back")} />
        <Typography sx={materialStyles.modalSubtitle}>
          {t("auth_modal.we_very_happy_see_you_back")}
        </Typography>
        <TabContext value={tabValue}>
          <Box>
            <TabList sx={materialStyles.tabList} onChange={handleTabChange}>
              <Tab
                sx={materialStyles.tabContainer}
                label={t("auth_modal.email")}
                value="EMAIL"
              />
              <Tab
                sx={materialStyles.tabContainer}
                label={t("auth_modal.phone")}
                value="PHONE"
              />
            </TabList>
          </Box>
          <Grid sx={materialStyles.formContainer}>
            <TabPanel sx={materialStyles.tabPanelContainer} value="EMAIL">
              <TextField
                error={Boolean(errors.email)}
                name="email"
                value={values.email}
                onChange={handleChange}
                sx={materialStyles.authInput}
                fullWidth
                label={t("auth_modal.email")}
                placeholder={t("auth_modal.enter_email")}
              />
            </TabPanel>
            <TabPanel sx={materialStyles.tabPanelContainer} value="PHONE">
              <TextField
                error={Boolean(errors.phone)}
                name="phone"
                type="number"
                sx={materialStyles.authInput}
                value={values.phone}
                onChange={handleChange}
                fullWidth
                label={t("auth_modal.phone")}
                placeholder={regUser.phoneParams.maskPlaceholder}
                inputProps={{ maxLength: phoneMaxLength }}
                InputProps={{
                  startAdornment: isShowPhoneCodes ? (
                    <PhoneCodesModal
                      onClickItem={onChoosePhoneParams}
                      phoneParams={regUser.phoneParams}
                    />
                  ) : null,
                }}
              />
            </TabPanel>
            <TextField
              error={Boolean(errors.password)}
              sx={materialStyles.authInput}
              name="password"
              value={values.password}
              onChange={handleChange}
              fullWidth
              type={showPassword ? "text" : "password"}
              label={t("auth_modal.password")}
              placeholder={t("auth_modal.enter_password")}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={onShowPassword}>
                    <RemoveRedEyeIcon sx={materialStyles.eyeIcon} />
                  </IconButton>
                ),
              }}
            />
            <Grid sx={materialStyles.forgotButtonContainer}>
              <p onClick={onForgotPassword} style={styles.forgotButton}>
                {t("auth_modal.forgot_password")}
              </p>
            </Grid>
            <Button
              type="submit"
              disabled={!isValid}
              sx={materialStyles.loginButton}
              variant="contained"
            >
              {t("auth_modal.login")}
            </Button>
            <OrBlock />
            <SocialButtons type="LOGIN" onAuthSuccess={onSocialAuthSuccess} />
            <ChangeAuthTypeBlock
              subText={t("auth_modal.dont_have_account")}
              actionText={t("auth_modal.sign_up_here")}
              onClick={onChangeModalType}
            />
          </Grid>
        </TabContext>
      </Grid>
    </form>
  );
};
