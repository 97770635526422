import { useCallback, useMemo } from "react";
import { UserLayout } from "../../components/layout/UserLayout";
import { MapComponent } from "../../components/map/MapComponent";
import { useAppDispatch, useAppSelector } from "../../store";
import _ from "lodash";
import { materialStyles, styles } from "./styles";
import { AppImageList } from "../../components/lists/imageList/AppImageList";
import { useTranslation } from "react-i18next";
import EmptyFavoritesIcon from "../../assets/images/icons/empty-favorites-icon.svg";
import { BlockEmptyWithIcon } from "../../components/blocks/blockEmpty/BlockEmptyWithIcon";
import {
  setMapPhotosFilters,
  updateAllowedPhotoInStorage,
} from "../../store/slices/map";
import { LoaderWithBackdrop } from "../../components/loaders/LoaderWithBackdrop";
import { IPhotoType } from "../../store/slices/myPhotos";
import {
  AppliedFilters,
  IAnyFilters,
} from "../../components/items/AppliedFilters";
import { PageTitle } from "../../components/titles/PageTitle";
import { Grid } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useFetchMapAllowedMedia } from "../../hooks/useFetchMapAllowedMedia";
import { useFetchMapPublicMedia } from "../../hooks/useFetchMapPublicMedia";
import { useNavigate, useParams } from "react-router-dom";

export const Map = () => {
  const { mode } = useAppSelector((state) => state.visualMode);
  const { user } = useAppSelector((state) => state.user);
  const { filters } = useAppSelector((state) => state.map);
  const { loading } = useAppSelector((state) => state.loader);

  const { username: defaultUsernameForFilters } = useParams();
  const navigate = useNavigate();

  const {
    allowedMedia,
    refetchAllowedMedia,
    onUpdatePageOrPageSize: onUpdateAllowedMediaPageOrPageSize,
  } = useFetchMapAllowedMedia();

  const {
    publicMedia,
    refetchPublicMedia,
    onUpdatePageOrPageSize: onUpdatePublicMediaPageOrPageSize,
  } = useFetchMapPublicMedia();

  const isAuth = useAuth();

  const { t } = useTranslation();

  const photosOnMap = useMemo(() => {
    switch (true) {
      case isAuth:
        return allowedMedia;
      case !isAuth:
        return publicMedia;
    }
  }, [allowedMedia, isAuth, publicMedia]);

  const dispatch = useAppDispatch();

  const onUpdatePhotoToFavorites = useCallback(
    (photo: IPhotoType) => {
      dispatch(updateAllowedPhotoInStorage(photo));
    },
    [dispatch],
  );

  const onRemoveFilters = useCallback(
    (updatedFilters: IAnyFilters) => {
      if (_.isEmpty(updatedFilters.peoples) && defaultUsernameForFilters) {
        navigate("/");
      }
      dispatch(setMapPhotosFilters(updatedFilters));
    },
    [defaultUsernameForFilters, dispatch, navigate],
  );

  const onClosePhotoModal = useCallback(() => {
    if (isAuth) {
      refetchAllowedMedia();
    } else {
      refetchPublicMedia();
    }
  }, [isAuth, refetchAllowedMedia, refetchPublicMedia]);

  const onEndReached = useCallback(() => {
    if (isAuth) {
      onUpdateAllowedMediaPageOrPageSize();
    } else {
      onUpdatePublicMediaPageOrPageSize();
    }
  }, [
    isAuth,
    onUpdateAllowedMediaPageOrPageSize,
    onUpdatePublicMediaPageOrPageSize,
  ]);

  return (
    <>
      <UserLayout onEndReached={onEndReached} disableMargin={mode === "GLOBE"}>
        {mode === "GRID" ? (
          <PageTitle title={t("map.map")}>
            {user ? (
              <Grid sx={materialStyles.filtersBlock}>
                <AppliedFilters
                  filters={filters}
                  type="MAP"
                  onRemoveFilter={onRemoveFilters}
                />
              </Grid>
            ) : null}
          </PageTitle>
        ) : null}
        {mode === "GRID" ? (
          <AppImageList
            containerStyle={materialStyles.appImageList}
            photos={photosOnMap}
            enableOpenPhoto
            type={user ? "DEFAULT" : "PUBLIC"}
            onUpdatePhotoToFavorites={onUpdatePhotoToFavorites}
            onClosePhotoModal={onClosePhotoModal}
          />
        ) : null}
        {user && mode === "GLOBE" ? (
          <Grid sx={materialStyles.mapFiltersContainer}>
            <Grid sx={materialStyles.filtersBlock}>
              <AppliedFilters
                filters={filters}
                type="MAP"
                onRemoveFilter={onRemoveFilters}
              />
            </Grid>
          </Grid>
        ) : null}
        {mode === "GLOBE" ? (
          <MapComponent
            onClosePhotoModal={onClosePhotoModal}
            containerStyle={styles.map}
            photos={photosOnMap}
          />
        ) : null}
        {_.isEmpty(photosOnMap) && mode === "GRID" ? (
          <BlockEmptyWithIcon
            Icon={<img src={EmptyFavoritesIcon} alt="EmptyFavoritesIcon" />}
            title={t("photos.photo_list_is_empty")}
          />
        ) : null}
      </UserLayout>
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
