import { createSlice } from "@reduxjs/toolkit";
import { IPhotoType } from "./myPhotos";
import _ from "lodash";
import { ITags } from "./tags";
import { IUserType } from "./user";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { catchChangesByKeys } from "../../utils";

export type IMapFilters = {
  tags: ITags[];
  peoples: IUserType[];
  created_to?: string;
  created_from?: string;
};

export const initialMapFilters = {
  tags: [],
  peoples: [],
};

interface IMapSliceInitialState {
  publicMedia: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
  allowedMedia: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
  filters: IMapFilters;
}

const mapSliceInitialState: IMapSliceInitialState = {
  publicMedia: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  allowedMedia: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  filters: initialMapFilters,
};

const mapSlice = createSlice({
  name: "map",
  initialState: mapSliceInitialState,
  reducers: {
    addPhotoToMap(state, action) {
      state.allowedMedia.data = [
        { ...action.payload.newPhoto, user: action.payload.user },
        ...state.allowedMedia.data,
      ];
    },
    setMapPublicMedia(state, action) {
      const allowedMedia = state.publicMedia.data;

      if (_.isEmpty(allowedMedia)) {
        state.publicMedia.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy(
        [...state.publicMedia.data, ...action.payload],
        "id",
      );

      state.publicMedia.data = updatedItems;
    },
    setMapAllowedMedia(state, action) {
      const allowedMedia = state.allowedMedia.data;

      if (_.isEmpty(allowedMedia)) {
        state.allowedMedia.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...allowedMedia, ...action.payload], "id");

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["is_favorite", "user"],
      );

      state.allowedMedia.data = itemsWithCheckedChanges;
    },
    updateAllowedMedia(state, action) {
      const updatedItems = _.uniqBy(
        [...state.allowedMedia.data, ...action.payload],
        "id",
      );
      state.allowedMedia.data = updatedItems;
    },
    updateAllowedPhotoInStorage(state, action) {
      const newPhoto = action.payload;
      const photos = state.allowedMedia.data;

      if (!newPhoto.id) {
        return;
      }

      const photoIndex = _.findIndex(
        photos,
        (photo) => photo.id === newPhoto.id,
      );

      const newPhotos = _.update(photos, photoIndex, () => newPhoto);

      state.allowedMedia.data = newPhotos;
    },
    resetMapMedia(state) {
      state.allowedMedia = mapSliceInitialState.allowedMedia;
      state.publicMedia = mapSliceInitialState.publicMedia;
      state.filters = mapSliceInitialState.filters;
    },
    removePhotoFromMap(state, action) {
      const removedPhotos = _.filter(
        state.allowedMedia.data,
        (photo) => photo.id !== action.payload,
      );

      state.allowedMedia.data = removedPhotos;
    },
    setMapPhotosFilters(state, action) {
      state.filters = action.payload;
    },
    setDefaultPhotosFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetMapPhotoFilters(state) {
      state.filters = initialMapFilters;
    },
    setMapAllowedMediaPage(state, action) {
      state.allowedMedia.page = action.payload;
    },
    setMapPublicMediaPage(state, action) {
      state.publicMedia.page = action.payload;
    },
    setMapAllowedMediaPageSize(state, action) {
      state.allowedMedia.pageSize = action.payload;
    },
    setMapPublicMediaPageSize(state, action) {
      state.publicMedia.pageSize = action.payload;
    },
    changeMediaLocationOnMapById(state, action) {
      const findedMedia = _.find(state.allowedMedia.data, {
        id: action.payload.id,
      });

      if (!findedMedia) {
        return;
      }

      findedMedia.latitude = action.payload.latitude;
      findedMedia.longitude = action.payload.longitude;

      state.allowedMedia.data = _.uniqBy(
        [...state.allowedMedia.data, findedMedia],
        "id",
      );
    },
    resetMapAllowedMedia(state) {
      state.allowedMedia.data = mapSliceInitialState.allowedMedia.data;
      state.allowedMedia.pageSize = mapSliceInitialState.allowedMedia.pageSize;
      state.allowedMedia.page = mapSliceInitialState.allowedMedia.page;
    },
    resetMapPublicMedia(state) {
      state.publicMedia.data = mapSliceInitialState.allowedMedia.data;
      state.publicMedia.page = mapSliceInitialState.publicMedia.page;
      state.publicMedia.pageSize = mapSliceInitialState.publicMedia.pageSize;
    },
  },
});

export default mapSlice.reducer;
export const {
  addPhotoToMap,
  setMapPublicMedia,
  setMapAllowedMedia,
  resetMapMedia,
  updateAllowedPhotoInStorage,
  setMapPhotosFilters,
  setDefaultPhotosFilters,
  resetMapPhotoFilters,
  setMapAllowedMediaPage,
  setMapPublicMediaPage,
  setMapAllowedMediaPageSize,
  setMapPublicMediaPageSize,
  updateAllowedMedia,
  resetMapAllowedMedia,
  resetMapPublicMedia,
  removePhotoFromMap,
} = mapSlice.actions;
