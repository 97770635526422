import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { IAlbumType } from "./albums";
import { ITags } from "./tags";
import { IUserType } from "./user";
import { IEmotionsKeys } from "../../hooks/useEmotions";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../constants/api";
import { catchChangesByKeys } from "../../utils";

export type TMediaFile = {
  fhd: string;
  hd: string;
  id: number;
  original: string;
  sd: string;
};

export enum MediaType {
  PHOTO = 0,
  VIDEO = 1,
  SLIDER = 2,
  AUDIO = 3,
  YOUTUBE_VIDEO = 4,
}

export type IPhotoEmotionsType = {
  [key: string]: number;
};

export type IPhotosFiltersType = {
  tag: ITags | null;
};

const initialPhotosFilter = {
  tag: null,
};

export type IPhotoType = {
  created_at: string;
  description: string;
  id: number;
  latitude: number;
  longitude: number;
  preview: string;
  thumbnail: string;
  original: string;
  full: string;
  title: string;
  is_favorite: boolean;
  user?: IUserType;
  comments?: number;
  emotions?: IPhotoEmotionsType;
  emotionsOwn?: IEmotionsKeys[];
  type: MediaType;
};

export type IFullPhotoType = {
  id: number;
  title: string;
  description: string;
  preview: string;
  thumbnail: string;
  created_at: string;
  latitude: number;
  longitude: number;
  original: string;
  full: string;
  metadata: Record<string, any>;
  tags: ITags[];
  albums: Pick<IAlbumType, "title" | "id">[];
  user_id: number;
  is_favorite: boolean;
  is_public: boolean;
  user: IUserType;
  comments?: number;
  emotions?: IPhotoEmotionsType;
  emotionsOwn?: IEmotionsKeys[];
  files: TMediaFile[];
  type: MediaType;
  is_custom_location: boolean;
};

interface IMyPhotosSliceInitialState {
  photos: {
    data: IPhotoType[];
    page: number;
    pageSize: number;
  };
  count: number | null;
  filters: IPhotosFiltersType;
}

const myPhotosSliceInitialState: IMyPhotosSliceInitialState = {
  photos: {
    data: [],
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  count: null,
  filters: initialPhotosFilter,
};

const myPhotosSlice = createSlice({
  name: "myPhotos",
  initialState: myPhotosSliceInitialState,
  reducers: {
    setMyPhotos(state, action) {
      const photos = state.photos.data;

      if (_.isEmpty(photos)) {
        state.photos.data = action.payload;
        return;
      }

      const updatedItems = _.uniqBy([...photos, ...action.payload], "id");

      const itemsWithCheckedChanges = catchChangesByKeys(
        updatedItems,
        action.payload,
        ["is_favorite"],
      );

      state.photos.data = itemsWithCheckedChanges;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    addPhoto(state, action) {
      state.photos.data = [
        ...state.photos.data,
        { ...action.payload.newPhoto, user: action.payload.user },
      ];
    },
    removePhoto(state, action) {
      const removedPhotos = _.filter(
        state.photos.data,
        (photo) => photo.id !== action.payload,
      );

      state.photos.data = removedPhotos;
    },
    updatePhotoInStorage(state, action) {
      const newPhoto = action.payload;
      const photos = state.photos.data;

      if (!newPhoto.id) {
        return;
      }

      const photoIndex = _.findIndex(
        photos,
        (photo) => photo.id === newPhoto.id,
      );
      const newPhotos = _.update(photos, photoIndex, () => newPhoto);

      state.photos.data = newPhotos;
    },
    resetPhotos(state) {
      state.photos = myPhotosSliceInitialState.photos;
      state.count = myPhotosSliceInitialState.count;
      state.filters = myPhotosSliceInitialState.filters;
    },
    setPhotosFilters(state, action) {
      state.filters = action.payload;
    },
    resetPhotoFilters(state) {
      state.filters = initialPhotosFilter;
    },
    setMyPhotosPage(state, action) {
      state.photos.page = action.payload;
    },
    setMyPhotosPageSize(state, action) {
      state.photos.pageSize = action.payload;
    },
    resetMyPhotos(state) {
      state.photos.data = myPhotosSliceInitialState.photos.data;
      state.photos.page = myPhotosSliceInitialState.photos.page;
      state.photos.pageSize = myPhotosSliceInitialState.photos.pageSize;
    },
  },
});

export default myPhotosSlice.reducer;
export const {
  addPhoto,
  removePhoto,
  setMyPhotos,
  setCount,
  resetPhotos,
  updatePhotoInStorage,
  setPhotosFilters,
  resetPhotoFilters,
  setMyPhotosPage,
  setMyPhotosPageSize,
  resetMyPhotos,
} = myPhotosSlice.actions;
