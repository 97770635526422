import React, { useMemo } from "react";
import { IPreparedFileInfo } from "../../../../store/slices/uploadFile";
import { Image } from "../../../images";
import { materialStyles } from "./styles";
import { Box, Grid } from "@mui/material";
import CustomActiveCheckboxIcon from "../../../../assets/images/icons/custom-checkbox-active.svg";
import { Video } from "../../../common/Video";
import _ from "lodash";
import {
  getYouTubeVideoCoverFromId,
  getYouTubeVideoIdFromUrl,
} from "../../../../utils/youtube";
import { FILE_VIDEO_EXT, FILE_VIDEO_NO_WEB_EXT } from "../../../../constants";
import VideocamIcon from "@mui/icons-material/Videocam";

type TSharedImageItem = {
  file: IPreparedFileInfo;
  isSelected?: boolean;
  onClick?: (file: IPreparedFileInfo, isSelected: boolean) => void;
};

export const SharedImageItem: React.FC<TSharedImageItem> = ({
  file,
  isSelected = false,
  onClick,
}) => {
  const parsedFileUrl = useMemo(() => {
    switch (file.type) {
      case "IMAGE":
      case "VIDEO":
      case "IMPORTED_GOOGLE_MEDIA":
        return file.url;
      case "YOUTUBE_VIDEO":
        const videoId = getYouTubeVideoIdFromUrl(file.url);
        return getYouTubeVideoCoverFromId(videoId);
    }
  }, [file.type, file.url]);

  return (
    <Box position="relative">
      {isSelected ? (
        <Box sx={materialStyles.selectedCheckBoxButton}>
          <img src={CustomActiveCheckboxIcon} alt="CustomActiveCheckboxIcon" />
        </Box>
      ) : null}
      {_.includes(
        ["IMAGE", "YOUTUBE_VIDEO", "IMPORTED_GOOGLE_MEDIA"],
        file.type,
      ) ? (
        <Image
          onClick={() => onClick && onClick(file, isSelected)}
          sx={[
            materialStyles.sharePhotosListItem,
            file.isHaveLocation
              ? materialStyles.sharePhotosListItemWithGeo
              : materialStyles.sharePhotosListItemNoGeo,
          ]}
          src={parsedFileUrl}
        />
      ) : null}
      {file.type === "VIDEO" && _.includes(FILE_VIDEO_EXT, file.ext) ? (
        <Video
          autoPlay={false}
          muted
          onClick={() => onClick && onClick(file, isSelected)}
          sx={[
            materialStyles.sharePhotosListItem,
            file.isHaveLocation
              ? materialStyles.sharePhotosListItemWithGeo
              : materialStyles.sharePhotosListItemNoGeo,
          ]}
          src={`${parsedFileUrl}#t=1`}
          playsInline
        />
      ) : null}
      {file.type === "VIDEO" && _.includes(FILE_VIDEO_NO_WEB_EXT, file.ext) ? (
        <Grid
          component="div"
          onClick={() => onClick && onClick(file, isSelected)}
          sx={[
            materialStyles.sharePhotosListItem,
            materialStyles.centerlizeContainer,
            file.isHaveLocation
              ? materialStyles.sharePhotosListItemWithGeo
              : materialStyles.sharePhotosListItemNoGeo,
          ]}
        >
          <VideocamIcon sx={materialStyles.videoCamEmptyIcon} />
        </Grid>
      ) : null}
    </Box>
  );
};
