import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { materialStyles } from "./styles";
import { UploadFileHeader } from "./UploadFileHeader";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import {
  getFileExtFromDroppedFile,
  parseGPSCoordinate,
} from "../../../../utils";
import UploadCloud from "../../../../assets/images/icons/upload-cloud.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  IPreparedFileInfo,
  setFile,
  TPrepareFileType,
} from "../../../../store/slices/uploadFile";
import { IUploadPhotoModalType } from "..";
import { setLoading } from "../../../../store/slices/loading";
import {
  FILE_IMAGE_APPLE_EXT,
  FILE_IMAGE_EXT,
  FILE_VIDEO_EXT,
  FILE_VIDEO_NO_WEB_EXT,
} from "../../../../constants";
import heic2any from "heic2any";
import { findEXIFinHEIC } from "../../../../utils/exif-heic";
import EXIF from "exif-js";
import { ALL_GROUP } from "../../../../hooks/usePrivacyGroups";
import Google from "../../../../assets/images/socials/Google.svg";
import YouTube from "../../../../assets/images/socials/YouTube.svg";

type IUploadNewFileForm = {
  onChangeUploadModalType: (type: IUploadPhotoModalType) => void;
  onClose?: () => void;
};

const MAX_FILES_COUNT = 1000;
const MAX_FILE_SIZE_IN_BYTES = 5368709120;

export const UploadNewFileForm: React.FC<IUploadNewFileForm> = ({
  onChangeUploadModalType,
  onClose,
}) => {
  const { t } = useTranslation();

  const { files } = useAppSelector((state) => state.uploadFile);
  const dispatch = useAppDispatch();

  const prepareFilesForShare = useCallback(async (file: File) => {
    try {
      const ext = getFileExtFromDroppedFile(file);

      let fileUrl;
      let exifFile;
      let fileArrayBuffer;
      let type: TPrepareFileType = "IMAGE";
      let fileExt;
      let size = file.size;

      switch (true) {
        case _.includes(FILE_IMAGE_APPLE_EXT, ext):
          const outputBlob = (await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 1,
          })) as Blob;
          fileUrl = URL.createObjectURL(outputBlob);
          fileArrayBuffer = await file.arrayBuffer();
          exifFile = findEXIFinHEIC(fileArrayBuffer);
          fileExt = ext;
          break;
        case _.includes(FILE_IMAGE_EXT, ext):
          fileUrl = URL.createObjectURL(file);
          fileArrayBuffer = await file.arrayBuffer();
          exifFile = EXIF.readFromBinaryFile(fileArrayBuffer);
          fileExt = ext;
          break;
        case _.includes(FILE_VIDEO_EXT, ext):
          type = "VIDEO";
          fileUrl = URL.createObjectURL(file);
          fileExt = ext;
          break;
        case _.includes(FILE_VIDEO_NO_WEB_EXT, ext):
          type = "VIDEO";
          fileExt = ext;
          fileUrl = URL.createObjectURL(file);
          break;
      }

      const fileLongitudeRef = exifFile?.GPSLongitudeRef;
      const fileLongitude = parseGPSCoordinate(
        exifFile?.GPSLongitude,
        fileLongitudeRef,
      );

      const fileLatitudeRef = exifFile?.GPSLatitudeRef;
      const fileLatitude = parseGPSCoordinate(
        exifFile?.GPSLatitude,
        fileLatitudeRef,
      );

      return {
        id: _.uniqueId(),
        url: fileUrl,
        isHaveLocation: Boolean(fileLatitude && fileLongitude),
        isHaveLocationByDefault: Boolean(fileLatitude && fileLongitude),
        longitude: fileLongitude,
        latitude: fileLatitude,
        privacyGroups: [ALL_GROUP],
        tags: [],
        description: "",
        type,
        ext: fileExt,
        fileName: file.name,
        size,
        originalFile: file,
      } as IPreparedFileInfo;
    } catch (error) {
      console.error("Error while [prepareFilesForShare]", error);
      return;
    }
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        dispatch(setLoading(true));

        const promises = _.map(acceptedFiles, (file) =>
          prepareFilesForShare(file),
        );

        const files = await Promise.all(promises);

        const preparedFiles = _.compact(files);

        const firstFile = _.first(preparedFiles);

        const firstFileWithLocation = _.find(preparedFiles, {
          isHaveLocation: true,
        });

        const fileForCover = firstFileWithLocation
          ? firstFileWithLocation
          : firstFile;

        if (!fileForCover) {
          return;
        }

        dispatch(
          setFile({
            filesInfo: preparedFiles,
            selectedFiles: preparedFiles,
            coverFile: fileForCover,
            metaData: undefined,
          }),
        );
      } catch (error) {
        console.error("Error while [onDrop]", error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, prepareFilesForShare],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES_COUNT,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    accept: [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/heic",
      "image/jfif",
      "video/*",
    ],
  });

  useEffect(() => {
    if (files && !_.isEmpty(files.filesInfo)) {
      onChangeUploadModalType("SHARE_UPLOADED_FILE");
    }
  }, [files, onChangeUploadModalType]);

  const onGoToEmbedYoutubeVideo = useCallback(
    () => onChangeUploadModalType("EMBED_YOUTUBE_VIDEO"),
    [onChangeUploadModalType],
  );

  const onGoToImportGoogleMedia = useCallback(
    () => onChangeUploadModalType("IMPORT_GOOGLE_MEDIA"),
    [onChangeUploadModalType],
  );

  return (
    <Grid sx={materialStyles.innerUploadModalBox}>
      <UploadFileHeader
        onClose={onClose}
        title={t("upload_photo.upload_new_photo")}
      />
      <Grid sx={materialStyles.uploadPhotoContent}>
        <Grid sx={materialStyles.uploadPhotoDropZone} {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={UploadCloud} alt="upload-icon" />
          <Typography sx={materialStyles.uploadPhotoSubtitle}>
            {t("upload_photo.drag_photos_and_videos_here")}
          </Typography>
          <Button sx={materialStyles.uploadButton} variant="contained">
            {t("upload_photo.browse")}
          </Button>
        </Grid>
      </Grid>
      <Box sx={materialStyles.additionalUploadButtonsContainer}>
        <Button
          onClick={onGoToEmbedYoutubeVideo}
          sx={materialStyles.socialButton}
          startIcon={<img src={YouTube} alt="YouTube" />}
          variant="outlined"
        >
          {t("upload_photo.embed_youtube_video")}
        </Button>
        <Button
          onClick={onGoToImportGoogleMedia}
          sx={materialStyles.socialButton}
          startIcon={<img src={Google} alt="Google" />}
          variant="outlined"
        >
          {t("upload_photo.upload_from_google_photo")}
        </Button>
      </Box>
    </Grid>
  );
};
