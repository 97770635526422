/* eslint-disable no-useless-escape */

export const CoordinatesRegex = /^(-?\d{1,2}\.\d+)\s(-?\d{1,3}\.\d+)$/;

export const validateEmail = (email: string) => {
  const regEx = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

  return regEx.test(email);
};

export const validatePhone = (phone: string) => {
  const regEx = /^\+[^a-zA-Z]*$/;

  return regEx.test(phone);
};

export const plusOnStart = (value: string) => {
  const regex = /^\+/;

  return regex.test(value);
};

export const getMaskLength = (value: string) => {
  const count = value.match(/X/g);

  if (!count) {
    return 0;
  }

  return count.length;
};

export const validateOnMediaLocation = (value: string) => {
  return CoordinatesRegex.test(value);
};
