import React, { useCallback, useEffect, useRef } from "react";
import { Video } from "../common/Video";
import { Box } from "@mui/material";
import { Playback } from "./components/Playback";
import { IMaterialStyleItem } from "../../types";
import { materialStyles } from "./styles";
import {
  TVideoEditorTimingsOptions,
  useVideoEditorReducer,
} from "../../hooks/useVideoEditorReducer";
import { IPreparedFileInfo } from "../../store/slices/uploadFile";
import { useLoadFFmpeg } from "../../hooks/useLoadFfmpeg";
import { useAppDispatch } from "../../store";
import { setLoading } from "../../store/slices/loading";
import { Toolbar } from "./components/Toolbar";
import { fetchFile } from "@ffmpeg/util";

interface IVideoEditor {
  id?: string;
  file: IPreparedFileInfo;
  sx?: IMaterialStyleItem;
  onSave?: (fileUrl: string) => void;
}

export const VideoEditor: React.FC<IVideoEditor> = ({
  file,
  sx,
  id,
  onSave,
}) => {
  const dispatch = useAppDispatch();

  const videoRef = useRef<HTMLVideoElement>(null);

  const { ffmpegRef } = useLoadFFmpeg(true);

  const {
    videoEditorState: { videoEditor },
    dispatchVideoEditor,
  } = useVideoEditorReducer();

  const onLoadVideoStart = useCallback(() => {
    dispatchVideoEditor({
      type: "SET_LOADING",
      value: true,
    });
  }, [dispatchVideoEditor]);

  const onLoadVideoEnd = useCallback(() => {
    dispatchVideoEditor({
      type: "SET_LOADING",
      value: false,
    });

    if (!videoRef.current) {
      return;
    }

    dispatchVideoEditor({
      type: "SET_DEFAULT_VIDEO_TIMINGS_OPTIONS",
      options: {
        start: 0,
        end: videoRef.current.duration,
      },
    });
  }, [dispatchVideoEditor]);

  const onChangeTimings = useCallback(
    (timings: TVideoEditorTimingsOptions) => {
      dispatchVideoEditor({
        type: "SET_TIMINGS",
        options: timings,
      });
    },
    [dispatchVideoEditor],
  );

  const onSaveVideo = useCallback(async () => {
    try {
      if (!ffmpegRef.current) {
        return;
      }
      dispatch(setLoading(true));

      const fetchedFile = await fetchFile(file.url);

      await ffmpegRef.current.writeFile("myFile.mp4", fetchedFile);

      await ffmpegRef.current.exec([
        "-ss",
        `${videoEditor.timings.start}`,
        "-accurate_seek",
        "-i",
        "myFile.mp4",
        "-to",
        `${videoEditor.timings.end}`,
        "-codec",
        "copy",
        "output.mp4",
      ]);

      const trimmedVideo = (await ffmpegRef.current.readFile(
        "output.mp4",
      )) as any;

      const url = URL.createObjectURL(
        new Blob([trimmedVideo.buffer], { type: "video/mp4" }),
      );

      onSave && onSave(url);
    } catch (error) {
      console.error("Error while [onSaveVideo]", error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [
    dispatch,
    ffmpegRef,
    file.url,
    onSave,
    videoEditor.timings.end,
    videoEditor.timings.start,
  ]);

  const onResetEditor = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    dispatchVideoEditor({ type: "SET_PLAYING", value: false });

    videoRef.current.pause();

    dispatchVideoEditor({ type: "RESET" });

    videoRef.current.currentTime = 0;
  }, [dispatchVideoEditor]);

  const onPlayOrStopVideo = useCallback(() => {
    dispatchVideoEditor({ type: "SET_PLAYING", value: !videoEditor.isPlaying });
  }, [dispatchVideoEditor, videoEditor.isPlaying]);

  const onMuteOrUnMuteVideo = useCallback(() => {
    dispatchVideoEditor({ type: "SET_MUTED", value: !videoEditor.isMuted });
  }, [dispatchVideoEditor, videoEditor.isMuted]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (videoEditor.isPlaying) {
      videoRef.current?.play();
      return;
    }

    videoRef.current?.pause();
  }, [videoEditor.isPlaying]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (videoEditor.isMuted) {
      videoRef.current.muted = true;
      return;
    }

    videoRef.current.muted = false;
  }, [videoEditor.isMuted]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const handleTimeUpdate = () => {
      if (!videoRef.current) {
        return;
      }

      if (videoRef.current.currentTime >= videoEditor.timings.end) {
        videoRef.current.pause();
        dispatchVideoEditor({ type: "SET_PLAYING", value: false });
        videoRef.current.currentTime = videoEditor.timings.start;
      }
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [dispatchVideoEditor, videoEditor.timings.end, videoEditor.timings.start]);

  return (
    <Box sx={materialStyles.container}>
      <Video
        onLoadedData={onLoadVideoEnd}
        onLoadStart={onLoadVideoStart}
        ref={videoRef}
        id={id}
        sx={sx}
        src={file.url}
        autoPlay={false}
        muted
        playsInline
      />
      <Playback
        videoRef={videoRef}
        timings={videoEditor.timings}
        initialVideoDuration={videoEditor.initialVideoDuration}
        onChangeTimings={onChangeTimings}
      />
      <Toolbar
        videoEditor={videoEditor}
        onSaveVideo={onSaveVideo}
        onResetEditor={onResetEditor}
        onPlayOrStopVideo={onPlayOrStopVideo}
        onMuteOrUnMuteVideo={onMuteOrUnMuteVideo}
      />
    </Box>
  );
};
