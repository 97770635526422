import { Colors, theme } from "../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../types";

export const styles: ICssStyleType = {};

export const materialStyles: IMaterialStyleType = {
  markerCountBlock: {
    display: "flex",
    position: "absolute",
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%",
    padding: "2px 6px",
    right: "-10px",
    top: "-10px",
    width: "28px",
    aspectRatio: 1,
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${Colors.white}`,
    zIndex: 2,
  },
  markerCount: {
    fontSize: "14px",
    color: Colors.white,
    fontWeight: 500,
  },
  marker: {
    minWidth: "38px",
    minHeight: "38px",
    width: "38px",
    height: "38px",
    maxWidth: "38px",
    maxHeight: "38px",
    borderRadius: "50%",
    transition: "0.2s",
    border: `2px solid ${Colors.white}`,
    overflow: "hidden",
  },
  videoMarker: {
    marginLeft: "-12px",
  },
  markersClusterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  markerContainer: {
    position: "relative",
  },
  typeMarketIconContainer: {
    position: "absolute",
    bottom: 2,
    left: 2,
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%",
    width: "16px",
    height: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  typeMarketVideoIconContainer: {
    right: 2,
    left: "unset",
  },
  typeMarketIcon: {
    width: "10px",
    height: "10px",
    color: Colors.white,
  },
  markerEmptyContainer: {
    minWidth: "38px",
    minHeight: "38px",
    width: "38px",
    height: "38px",
    maxWidth: "38px",
    maxHeight: "38px",
    borderRadius: "50%",
    transition: "0.2s",
    border: `2px solid ${Colors.white}`,
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  videoCamEmptyIcon: {
    color: theme.palette.grey[100],
  },
};
