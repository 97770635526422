import { AppDispatch, RootState } from "..";
import axios, { AxiosError } from "axios";
import { API_URL } from "../api";
import { setLoading } from "../slices/loading";
import { setError } from "../slices/errors";
import {
  getResponseFromAxiosError,
  parseFiltersParamsForMediaRequest,
} from "../../utils";
import { IPhotosFiltersType } from "../slices/myPhotos";
import { checkOrRefreshToken } from "./user";
import _ from "lodash";
import { setCloudStorage } from "../slices/system";
import {
  resetStack,
  setCurrentStackProcent,
  setUploaderStack,
} from "../slices/uploaderStack";

type ChunkPart = { id: number; etag: string };

export const uploadPhoto = (
  title: string,
  description: string,
  file: Blob,
  latitude: number,
  longitude: number,
  isPublic: boolean,
  tagIds: number[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setUploaderStack({ event: title, procent: 0 }));

      const { user } = getState();

      const { data } = await axios.post(
        `${API_URL}/media/photo`,
        {
          title,
          description,
          file,
          latitude,
          longitude,
          is_public: isPublic ? 1 : 0,
          tags: tagIds,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const procent = Math.round((event.loaded / event.total) * 100);
              dispatch(setCurrentStackProcent(procent));
            }
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [uploadPhoto]", error);
      throw new AxiosError(error);
    } finally {
      dispatch(resetStack());
    }
  };
};

export const uploadVideo = (
  title: string,
  description: string,
  file: Blob,
  latitude: number,
  longitude: number,
  isPublic: boolean,
  tagIds: number[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setUploaderStack({ event: title, procent: 0 }));

      const { user } = getState();

      const { data } = await axios.post(
        `${API_URL}/media/video`,
        {
          title,
          description,
          file,
          latitude,
          longitude,
          is_public: isPublic ? 1 : 0,
          tags: tagIds,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const procent = Math.round((event.loaded / event.total) * 100);
              dispatch(setCurrentStackProcent(procent));
            }
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [uploadVideo]", error);
    } finally {
      dispatch(resetStack());
    }
  };
};

export const startUploadVideoByChunks = (
  fileName: string,
  fileSize: number,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const { data } = await axios.post(
        `${API_URL}/media/multipart-start`,
        {
          filename: fileName,
          size: fileSize,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [startUploadVideoByChunks]", error);
    }
  };
};

export const createUploadVideoChunkLink = (
  upload: string,
  position: number,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const { data } = await axios.get(
        `${API_URL}/media/multipart-chunk/${upload}/${position}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [createUploadVideoChunkLink]", error);
    }
  };
};

export const uploadVideoByChunks = ({
  file,
  description,
  latitude,
  longitude,
  isPublic,
  tagIds,
}: {
  file: File | null;
  description: string;
  latitude: number;
  longitude: number;
  isPublic: boolean;
  tagIds: number[];
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!file) {
        throw new Error();
      }

      const fileNameWithExt = `${_.uniqueId("user_video_")}.${file.type.split("/")[1]}`;

      dispatch(setUploaderStack({ event: fileNameWithExt, procent: 0 }));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const uploadTag = await dispatch(
        startUploadVideoByChunks(fileNameWithExt, file.size),
      );

      const partSize = 100_000_000;

      const emptyArrayForCreateChunks = Array.from({
        length: Math.ceil(file.size / partSize),
      });

      const parts: ChunkPart[] = [];

      const chunkPromises = async () => {
        let partIndex = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _element of emptyArrayForCreateChunks) {
          const position = partIndex + 1;
          const start = partIndex * partSize;
          const end = Math.min(start + partSize, file.size);
          const chunk = file.slice(start, end);

          const link = await dispatch(
            createUploadVideoChunkLink(uploadTag, position),
          );

          const previosProcent =
            position !== 1
              ? ((position - 1) / emptyArrayForCreateChunks.length) * 100
              : 0;

          const result = await axios.put(link, chunk, {
            onUploadProgress: (event) => {
              if (event.total) {
                const chunkProcent =
                  (event.loaded / event.total) *
                  (1 / emptyArrayForCreateChunks.length) *
                  100;
                dispatch(setCurrentStackProcent(previosProcent + chunkProcent));
              }
            },
          });

          const procent = (position / emptyArrayForCreateChunks.length) * 100;
          dispatch(setCurrentStackProcent(procent));

          const parsedEtag = result.headers?.etag?.replace(/"/g, "");

          partIndex++;
          parts.push({ id: position, etag: parsedEtag });
        }
      };

      await chunkPromises();

      const { data } = await axios.post(
        `${API_URL}/media/video`,
        {
          title: fileNameWithExt,
          description,
          file: uploadTag,
          latitude,
          longitude,
          is_public: isPublic ? 1 : 0,
          tags: tagIds,
          parts,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [uploadVideoByChunks]", error);
    } finally {
      dispatch(resetStack());
    }
  };
};

export const uploadYouTubeVideo = (
  title: string,
  description: string,
  file: string,
  latitude: number,
  longitude: number,
  isPublic: boolean,
  tagIds: number[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setUploaderStack({ event: title, procent: 0 }));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/media/youtube`,
        {
          title,
          description,
          file,
          latitude,
          longitude,
          is_public: isPublic ? 1 : 0,
          tags: tagIds,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const procent = Math.round((event.loaded / event.total) * 100);
              dispatch(setCurrentStackProcent(procent));
            }
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [uploadYouTubeVideo]", error);
    } finally {
      dispatch(resetStack());
    }
  };
};

export const uploadImportedGoogleMedia = (
  title: string,
  description: string,
  file: string,
  latitude: number,
  longitude: number,
  isPublic: boolean,
  tagIds: number[],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const { data } = await axios.post(
        `${API_URL}/media/google/import`,
        {
          items: [
            {
              title,
              description,
              id: file,
              latitude,
              longitude,
              is_public: isPublic ? 1 : 0,
              tags: tagIds,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const procent = Math.round((event.loaded / event.total) * 100);
              dispatch(setCurrentStackProcent(procent));
            }
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [uploadImportedGoogleMedia]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const convertVideoToMp4 = ({ file }: { file: Blob }) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const formData = new FormData();

      formData.append("file", file);

      const { data } = await axios.post(
        `${API_URL}/media/video-convert`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${checkedToken}`,
          },
          responseType: "blob",
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [convertVideoToMp4]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMedia = (
  page?: number,
  pageSize?: number,
  filters?: IPhotosFiltersType,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const parsedFilters = parseFiltersParamsForMediaRequest(filters);

      const { data } = await axios.get(`${API_URL}/globe/allowed`, {
        params: {
          page,
          user_id: user.id,
          limit: pageSize,
          ...parsedFilters,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMedia]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMediaById = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data: mediaData } = await axios.get(`${API_URL}/media/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!mediaData) {
        throw new Error();
      }

      const { data: sharingUsersData } = await axios.get(
        `${API_URL}/media/${id}/access`,
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!sharingUsersData) {
        throw new Error();
      }

      return { mediaData, sharingUsersData };
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMediaById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMediaSharingGroupsById = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/media/${id}/access`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMediaSharingGroupsById]", error);
    }
  };
};

export const sharingMediaByGroupId = (mediaId: number, groupId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/media/${mediaId}/access/${groupId}`,
        {
          is_visible: true,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [sharingMediaByGroupId]", error);
    }
  };
};

export const removeSharingMediaByGroupId = (
  mediaId: number,
  groupId: number,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(
        `${API_URL}/media/${mediaId}/access/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [removeSharingMediaByGroupId]", error);
    }
  };
};

export const sharingMediaByGroupIds = (mediaId: number, groupIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      const promises = _.map(groupIds, (groupId) =>
        dispatch(sharingMediaByGroupId(mediaId, groupId)),
      );

      const response = await Promise.all(promises);

      if (_.isEmpty(response)) {
        throw new Error();
      }

      return response;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [sharingMediaByGroupIds]", error);
    }
  };
};

export const removeSharingMediaByGroupIds = (
  mediaId: number,
  groupIds: number[],
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const promises = _.map(groupIds, (groupId) =>
        dispatch(removeSharingMediaByGroupId(mediaId, groupId)),
      );

      const response = await Promise.all(promises);

      if (_.isEmpty(response)) {
        throw new Error();
      }

      return response;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [removeSharingMediaByGroupIds]", error);
    }
  };
};

export const changeMediaAccess = (mediaId: number, isPublic: boolean) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/media/${mediaId}/access`,
        {
          is_visible: isPublic,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [changeMediaAccess]", error);
    }
  };
};

export const removeMediaById = (id: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.delete(`${API_URL}/media/${id}`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [getMediaById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const editMediaById = (
  id: number,
  additionalParams?: {
    latitude?: number;
    longitude?: number;
  },
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { user, edit } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const description = _.get(edit, "props.prop1");

      const { data } = await axios.post(
        `${API_URL}/media/${id}`,
        {
          description,
          latitude: additionalParams?.latitude,
          longitude: additionalParams?.longitude,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error white [editMediaById]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMediaStorageInfo = (token?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(
        checkOrRefreshToken(token || user.token),
      );

      const { data } = await axios.get(`${API_URL}/media/storage`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      dispatch(setCloudStorage(data));
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [getMediaStorageInfo]", error);
    }
  };
};

export const getMediaComments = (photoId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/media/${photoId}/comments`, {
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data.list;
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [getMediaComments]", error);
    }
  };
};

export const addNewMediaComment = (photoId: number, comment: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.post(
        `${API_URL}/media/${photoId}/comments`,
        {
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${checkedToken}`,
          },
        },
      );

      if (!data) {
        throw new Error();
      }

      if (!data.success) {
        throw new Error();
      }
    } catch (error: any) {
      dispatch(setError(getResponseFromAxiosError(error)));
      console.error("Error while [addNewMediaComment]", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMediaFromGoogleAccount = (pageToken?: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const { user } = getState();

      const checkedToken = await dispatch(checkOrRefreshToken(user.token));

      const { data } = await axios.get(`${API_URL}/media/google`, {
        params: {
          pageToken,
        },
        headers: {
          Authorization: `Bearer ${checkedToken}`,
        },
      });

      if (!data) {
        throw new Error();
      }

      return data;
    } catch (error: any) {
      throw error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
