import { Colors, theme } from "../../../../theme";
import { IMaterialStyleType, ICssStyleType } from "../../../../types";
import { withHexOpacity } from "../../../../utils";

export const styles: ICssStyleType = {
  droppedFile: {
    display: "flex",
    flex: 1,
    position: "relative",
  },
  sharedFile: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    objectFit: "contain",
    flex: 1,
  },
  selectCover: {
    width: "100px",
    height: "126px",
    borderRadius: "6px",
    objectFit: "cover",
  },
};

export const materialStyles: IMaterialStyleType = {
  innerUploadModalBox: {
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "710px",
    },
    height: {
      xs: `98vh`,
      sm: `98vh`,
      md: "710px",
    },
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  innerYouTubeUploadModalBox: {
    width: {
      xs: `98vw`,
      sm: `98vw`,
      md: "710px",
    },
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  uploadModalHeaderBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "19px 22px",
  },
  uploadPhotoHeaderTitle: {
    color: Colors.white,
    fontSize: {
      xs: `16px`,
      sm: `16px`,
      md: "20px",
    },
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
    },
    lineHeight: "28px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  backIcon: {
    color: Colors.white,
  },
  headerNextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
  information: {
    fontSize: {
      xs: `12px`,
      sm: `12px`,
      md: "16px",
    },
    color: theme.palette.grey[200],
  },
  nextButton: {
    textTransform: "none",
    letterSpacing: 0,
    fontSize: {
      xs: `16px`,
      sm: `16px`,
      md: "20px",
    },
    fontWeight: 400,
    lineHeight: "28px",

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  uploadPhotoContent: {
    display: "flex",
    overflow: "auto",
    flex: 1,
  },
  uploadPhotoDropZone: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uploadPhotoSubtitle: {
    color: theme.palette.grey[900],
    fontSize: "20px",
    lineHeight: "28px",
    marginTop: "24px",
    marginBottom: "19px",
  },
  uploadButton: {
    backgroundColor: theme.palette.error.main,
    textTransform: "none",
    color: Colors.white,
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "8px",
    fontWeight: 500,
    width: "200px",
    height: "50px",

    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  shareContent: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    overflow: "auto",
  },
  shareFormBlock: {
    flex: 1,
    padding: "0px 20px 20px",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  userBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  userName: {
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey["A100"],
  },
  captionInput: {
    border: `1px solid ${theme.palette.grey[900]}`,
    p: 1,
    borderRadius: 1,
    marginTop: "14px",
    marginBottom: "8px",

    color: theme.palette.grey[900],

    "& .MuiInputBase-root": {
      padding: 0,
      color: theme.palette.grey[900],
    },

    "& fieldset": {
      border: "none",
      padding: 0,
    },
  },
  selectCoverBlock: {
    marginTop: "14px",
    flexDirection: "column",

    display: {
      xs: `flex`,
      sm: `flex`,
      md: "none",
    },
  },
  selectCoverText: {
    fontSize: "12px",
    lineHeight: "19px",
    color: theme.palette.grey[900],
    marginTop: "6px",
  },
  sharedButtonsContainer: {
    display: "flex",
    marginTop: "14px",
    flexDirection: "column",
    gap: "14px",
  },
  sharedButtonsIcon: {
    color: theme.palette.grey[700],
    width: "18px",
    height: "18px",
  },
  tagsList: {
    width: "100%",
    margin: "14px 0 0",
  },
  specifyLocationButton: {
    fontSize: "14px",
    mb: 2,

    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  sharePhotosContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#101010",
    position: "relative",
  },
  sharePhotosListContainer: {
    display: "grid",

    gridTemplateColumns: {
      xs: "repeat(3, 1fr)",
      sm: "repeat(3, 1fr)",
      md: "repeat(6, 1fr)",
    },
    width: "100%",
    overflow: "auto",
    minHeight: "120px",
    maxHeight: "280px",
    transition: "0.2s",
  },
  sharePhotosListContainerX8: {
    gridTemplateColumns: {
      xs: "repeat(3, 1fr)",
      sm: "repeat(3, 1fr)",
      md: "repeat(8, 1fr)",
    },
  },
  sharePhotosListItem: {
    objectFit: "contain",
    width: "90%",
    aspectRatio: 1,
    backgroundColor: "#101010",
    transition: "0.2s",
    borderRadius: 2,
    mb: 1,
    m: 0.5,
  },
  sharePhotosListItemWithGeo: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  sharePhotosListItemNoGeo: {
    border: `1px solid ${theme.palette.error.dark}`,
  },
  selectOrDeleselectTitle: {
    color: Colors.white,
    textTransform: "uppercase",
    fontWeight: "700",
  },
  mediaToolbarConrainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    p: 2,
    flexWrap: "wrap",
  },
  selectOrDeleselectContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1.5,
    flexWrap: "wrap",
  },
  selectOrDeselectButton: {
    display: "flex",
    alignItems: "center",
    padding: "6px 14px 6px 14px",
    borderRadius: "20px",
    backgroundColor: withHexOpacity(Colors.white, 0.12),
    border: `1px solid ${withHexOpacity(Colors.white, 0.12)}`,
    flexDirection: "row",
    height: "38px",
    textTransform: "uppercase",
    color: Colors.white,
  },
  selectOrDeselectButtonActive: {
    border: `1px solid ${withHexOpacity(Colors.white, 1)}`,
  },
  selectOrDeselectButtonWithGeo: {
    backgroundColor: theme.palette.success.main,
  },
  selectOrDeselectButtonNoGeo: {
    backgroundColor: theme.palette.error.dark,
  },
  selectedCheckBoxButton: {
    position: "absolute",
    top: "6px",
    right: "8px",
  },
  selectOrDeleselectButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
  },
  mobileSettingsPhotoButton: {
    position: "absolute",
    color: Colors.white,
    right: "16px",
    top: "16px",
    backgroundColor: withHexOpacity("#313131", 0.8),

    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
    zIndex: 2,
  },
  mobileSettingsPhotoCloseButton: {
    color: Colors.white,
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  mobileSettingsPhotoCloseButtonContainer: {
    display: {
      xs: "flex",
      sm: "flex",
      md: "none",
    },
  },
  tagsListTitle: {
    mb: 1.5,
    fontSize: "14px",
    lineHeight: "22px",
    color: theme.palette.grey[700],
  },
  tagsSearchAutocomplete: {
    mb: 1.5,
  },
  editVideoButton: {
    mt: {
      xs: 1,
      sm: 1,
      md: 0,
    },
  },
  socialButton: {
    height: "53px",
    textTransform: "none",
    flex: 1,
    color: theme.palette.grey[400],
    fontSize: "12px",
    letterSpacing: 0,
    border: `1px solid ${theme.palette.grey[100]} !important`,
    maxWidth: "250px",

    "& p": {
      display: {
        xs: `none`,
        sm: `none`,
        md: "flex",
      },
    },

    "& .MuiButton-startIcon": {
      width: "20px",
      height: "20px",
      marginLeft: {
        xs: 0,
        sm: 0,
        md: "-4px",
      },
      marginRight: {
        xs: 0,
        sm: 0,
        md: "8px",
      },
    },
  },
  additionalUploadButtonsContainer: {
    p: 3,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    gap: 2,
  },
  embedVideoFormContainer: {
    pb: 4,
    pl: 4,
    pr: 4,
  },
  embedYoutubeVideoText: {
    color: Colors.white,
    ml: 2,
    mr: 2,
    mb: 3,
  },
  youTubeLinkInput: {
    backgroundColor: theme.palette.grey[600],

    "& .MuiInputBase-root": {
      backgroundColor: `${theme.palette.grey[600]} !important`,
    },

    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.grey[600],
      borderColor: theme.palette.background.default,

      "&.Mui-focused": {
        backgroundColor: theme.palette.grey[600],
      },

      "&.Mui-focused:after": {
        display: "block",
        borderBottom: `1px solid ${theme.palette.grey["A100"]}`,
      },

      "&::before, &::after": {
        display: "none",
      },

      "& :hover": {
        backgroundColor: theme.palette.grey[600],
      },
    },
    "& input": {
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "20px",
      fontSize: "18px",
      color: theme.palette.grey["A100"],
      backgroundColor: theme.palette.grey[600],
    },
    "& fieldset": {
      border: "none",
    },
  },
  googleImportFormContainer: {
    pb: 4,
    pl: 4,
    pr: 4,
    overflow: "auto",
  },
  googleImportErrorFormContainer: {
    pl: 4,
    pr: 4,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  googleImportText: {
    color: Colors.white,
    ml: 2,
    mr: 2,
    mb: 3,
  },
  googleImportIsPublicSwitcherContainer: {
    pt: 2,
    pb: 2,
  },
  closeButton: {
    position: "absolute",
    right: "14px",
    top: "14px",
    color: Colors.white,
  },
  googleImportFormItem: {
    p: 0.2,
  },
  sharedIframe: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    objectFit: "contain",
    flex: 1,

    maxHeight: {
      xs: `300px`,
      sm: `300px`,
      md: "none",
    },
  },
  emptyCoverContainer: {
    display: "flex",
    width: "100%",
    overflow: "hidden",
    objectFit: "contain",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  videoCamEmptyIcon: {
    color: theme.palette.grey[700],
    width: "24px",
    height: "24px",
  },
  emptyCoverContainerTitle: {
    color: theme.palette.grey[700],
    textAlign: "center",
    margin: "12px",
  },
  centerlizeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
