import {
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { materialStyles, styles } from "./styles";
import { UploadFileHeader } from "./UploadFileHeader";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IUploadPhotoModalType } from "..";
import { UserAvatar } from "../../../user/UserAvatar";
import { AddLocationForFileModal } from "../../addLocationForFileModal";
import {
  sharingMediaByGroupIds,
  uploadImportedGoogleMedia,
  uploadPhoto,
  uploadVideoByChunks,
  uploadYouTubeVideo,
} from "../../../../store/thunks/media";
import _ from "lodash";
import { addPhoto } from "../../../../store/slices/myPhotos";
import { useToast } from "rc-toastr";
import {
  changeDescriptionInSelectedFiles,
  IPreparedFileInfo,
  removePhotoFromUploader,
  removePrivacyGroupFromSelectedFiles,
  removeSelectedFile,
  removeTagsFromSelectedFiles,
  resetFile,
  resetPrivacyGroupInSelectedFiles,
  resetSelectedFiles,
  selectFiles,
  setPrivacyGroupInSelectedFiles,
  setTagsInSelectedFiles,
} from "../../../../store/slices/uploadFile";
import { getTags } from "../../../../store/thunks/tags";
import { LoaderWithBackdrop } from "../../../loaders/LoaderWithBackdrop";
import { TagsList } from "../../../lists/tagsList";
import { ITags, setTags } from "../../../../store/slices/tags";
import { addPhotoToMap } from "../../../../store/slices/map";
import { GroupsList } from "../../../lists/groupsList";
import {
  ALL_GROUP,
  usePrivacyGroups,
} from "../../../../hooks/usePrivacyGroups";
import { PrivacyGroup } from "../../../../store/slices/groups";
import { SharedImageItem } from "./SharedImageItem";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { AppAutocomplete } from "../../../inputs/AppAutocomplete";
import { useSearchTags } from "../../../../hooks/useSearchTags";
import { FiltersAutocompleteInput } from "../../../inputs/FiltersAutocompleteInput";
import { AutocompleteOptionWithTitle } from "../../../items/AutocompleteOptionWithTitle";
import { Image } from "../../../common/Image";
import { Video } from "../../../common/Video";
import { VideoEditorModal } from "../../videoEditorModal";
import { setVideo } from "../../../../store/slices/videoEditor";
import { setError } from "../../../../store/slices/errors";
import { getResponseFromAxiosError } from "../../../../utils";
import { AxiosError } from "axios";
import { YouTubeIFrame } from "../../../common/YouTubeIFrame";
import { getYouTubeVideoIdFromUrl } from "../../../../utils/youtube";
import { ButtonWithIcon } from "../../../buttons/ButtonWithIcon";
import { setLoading } from "../../../../store/slices/loading";
import { FILE_VIDEO_EXT, FILE_VIDEO_NO_WEB_EXT } from "../../../../constants";
import VideocamIcon from "@mui/icons-material/Videocam";

type IShareUploadedFileForm = {
  onChangeUploadModalType: (type: IUploadPhotoModalType) => void;
  onClose: () => void;
};

const DISPLAY_TAGS_FROM_LENGTH = 3;
const MAX_VIDEO_EDITOR_SIZE_IN_BYTES = 2147483648;

//TODO: Refactor this component. Separate to components
export const ShareUploadedFileForm: React.FC<IShareUploadedFileForm> = ({
  onChangeUploadModalType,
  onClose,
}) => {
  const { t } = useTranslation();

  const [isOpenAddLocation, setIsOpenAddLocation] = useState(false);
  const [isOpenVideoEditor, setIsOpenVideoEditor] = useState(false);
  const { loading } = useAppSelector((state) => state.loader);

  const { groups } = usePrivacyGroups();

  const { toast } = useToast();

  const { files } = useAppSelector((state) => state.uploadFile);
  const { user } = useAppSelector((state) => state.user);

  const [tagsSearch, setTagsSearch] = useState("");

  const {
    findedTags,
    loading: loadingSearchTags,
    debouncedSearch: debouncedTagsSearch,
  } = useSearchTags(tagsSearch);

  const [selectedImageType, setSelectedImageType] = useState<
    "ALL" | "GEO" | "NO_GEO" | "CUSTOM"
  >("ALL");

  const [isOpenMobileSettingsForm, setIsOpenMobileSettingsForm] =
    useState(false);

  const isEmptySelectedFiles = useMemo(
    () => _.isEmpty(files?.selectedFiles),
    [files?.selectedFiles],
  );

  const isDisabledSetFilesLocation = useMemo(() => {
    if (!files) {
      return false;
    }

    const sizeSelectedFilesWithLocationByDefault = _.chain(files.selectedFiles)
      .filter("isHaveLocationByDefault")
      .size()
      .value();

    if (
      sizeSelectedFilesWithLocationByDefault === _.size(files.selectedFiles)
    ) {
      return true;
    }

    return false;
  }, [files]);

  const privacyGroupsFromSelectedFiles = useMemo(() => {
    if (!files) {
      return [];
    }
    const privacyGroups = _.chain(files.selectedFiles)
      .reduce((memo, file) => {
        return [...memo, ...file.privacyGroups];
      }, [] as PrivacyGroup[])
      .uniqBy("id")
      .value();

    return privacyGroups;
  }, [files]);

  const tagsFromSelectedFiles = useMemo(() => {
    if (!files) {
      return [];
    }
    const tags = _.chain(files.selectedFiles)
      .reduce((memo, file) => {
        return [...memo, ...file.tags];
      }, [] as ITags[])
      .uniqBy("id")
      .value();

    return tags;
  }, [files]);

  const isShowYouTubeCover = useMemo(() => {
    if (!files) {
      return;
    }

    const lastSelectedFile = _.last(files.selectedFiles);

    if (lastSelectedFile) {
      return lastSelectedFile.type === "YOUTUBE_VIDEO";
    }

    return files.coverFile?.type === "YOUTUBE_VIDEO";
  }, [files]);

  const isShowVideoCover = useMemo(() => {
    if (!files) {
      return;
    }

    const lastSelectedFile = _.last(files.selectedFiles);

    if (lastSelectedFile) {
      return (
        lastSelectedFile.type === "VIDEO" &&
        _.includes(FILE_VIDEO_EXT, lastSelectedFile.ext)
      );
    }

    return (
      files.coverFile?.type === "VIDEO" &&
      _.includes(FILE_VIDEO_EXT, files.coverFile?.ext)
    );
  }, [files]);

  const isShowVideoCoverWithoutPlayer = useMemo(() => {
    if (!files) {
      return;
    }

    const lastSelectedFile = _.last(files.selectedFiles);

    if (lastSelectedFile) {
      return (
        lastSelectedFile.type === "VIDEO" &&
        _.includes(FILE_VIDEO_NO_WEB_EXT, lastSelectedFile.ext)
      );
    }

    return (
      files.coverFile?.type === "VIDEO" &&
      _.includes(FILE_VIDEO_NO_WEB_EXT, files.coverFile?.ext)
    );
  }, [files]);

  const isAvailableVideoEditor = useMemo(() => {
    if (!files) {
      return false;
    }

    if (!isShowVideoCover) {
      return false;
    }

    const lastSelectedFile = _.last(files.selectedFiles);

    if (lastSelectedFile) {
      return (
        _.includes(FILE_VIDEO_EXT, lastSelectedFile.ext) &&
        Number(lastSelectedFile?.size) < MAX_VIDEO_EDITOR_SIZE_IN_BYTES
      );
    }

    return (
      _.includes(FILE_VIDEO_EXT, _.get(files, "coverFile.ext")) &&
      Number(_.get(files, "coverFile.size")) < MAX_VIDEO_EDITOR_SIZE_IN_BYTES
    );
  }, [files, isShowVideoCover]);

  const isShowImageCover = useMemo(() => {
    if (!files) {
      return;
    }

    const lastSelectedFile = _.last(files.selectedFiles);

    if (lastSelectedFile) {
      return _.includes(
        ["IMAGE", "IMPORTED_GOOGLE_MEDIA"],
        lastSelectedFile.type,
      );
    }

    return _.includes(
      ["IMAGE", "IMPORTED_GOOGLE_MEDIA"],
      files.coverFile?.type,
    );
  }, [files]);

  const isGoogleImportedFileSelected = useMemo(() => {
    if (!files) {
      return;
    }

    const lastSelectedFile = _.last(files.selectedFiles);

    if (lastSelectedFile) {
      return lastSelectedFile.type === "IMPORTED_GOOGLE_MEDIA";
    }

    return files.coverFile?.type === "IMPORTED_GOOGLE_MEDIA";
  }, [files]);

  const isAllGroupSelected = useMemo(
    () => Boolean(_.find(privacyGroupsFromSelectedFiles, { id: ALL_GROUP.id })),
    [privacyGroupsFromSelectedFiles],
  );

  const selectedFilesWithoutLocation = useMemo(() => {
    if (!files) {
      return;
    }

    return _.chain(files.selectedFiles)
      .filter({ isHaveLocation: false })
      .size()
      .value();
  }, [files]);

  const selectedFilesTags = useMemo(() => {
    if (!files) {
      return [];
    }

    const tags = _.chain(files.selectedFiles)
      .reduce((memo, file) => {
        return [...memo, ...file.tags];
      }, [] as ITags[])
      .uniqBy("id")
      .value();

    return tags;
  }, [files]);

  const dispatch = useAppDispatch();

  const onBack = useCallback(() => {
    if (isOpenMobileSettingsForm) {
      setIsOpenMobileSettingsForm(false);
      return;
    }

    dispatch(resetFile());
    onChangeUploadModalType("UPLOAD_NEW_PHOTO");
  }, [dispatch, isOpenMobileSettingsForm, onChangeUploadModalType]);

  const onOpenAddLocation = useCallback(() => {
    setIsOpenAddLocation(true);
  }, []);

  const onCloseAddLocation = useCallback(() => {
    setIsOpenAddLocation((state) => !state);
  }, []);

  const onChangeDescription = useCallback(
    (event: any) => {
      const { value } = event.target;
      dispatch(changeDescriptionInSelectedFiles(value));
    },
    [dispatch],
  );

  const onUploadFile = useCallback(
    async (file: IPreparedFileInfo) => {
      try {
        let fetchedFile;
        let fileInBlob;

        const latitude = file.latitude || 0;
        const longitude = file.longitude || 0;

        const isPublic = Boolean(_.find(file.privacyGroups, { isAll: true }));

        let response;

        switch (file.type) {
          case "IMAGE":
            fetchedFile = await fetch(file.url);
            fileInBlob = await fetchedFile.blob();

            response = await dispatch(
              uploadPhoto(
                _.uniqueId("user_image_"),
                file.description,
                fileInBlob,
                latitude,
                longitude,
                isPublic,
                _.map(file.tags, (tag) => tag.id),
              ),
            );
            break;
          case "VIDEO":
            response = await dispatch(
              uploadVideoByChunks({
                file: file.originalFile,
                description: file.description,
                latitude,
                longitude,
                isPublic,
                tagIds: _.map(file.tags, (tag) => tag.id),
              }),
            );
            break;
          case "YOUTUBE_VIDEO":
            response = await dispatch(
              uploadYouTubeVideo(
                _.uniqueId("youtube_video_"),
                file.description,
                file.url,
                latitude,
                longitude,
                isPublic,
                _.map(file.tags, (tag) => tag.id),
              ),
            );
            break;
          case "IMPORTED_GOOGLE_MEDIA":
            response = await dispatch(
              uploadImportedGoogleMedia(
                _.uniqueId("imported_google_media_"),
                file.description,
                file.id,
                latitude,
                longitude,
                isPublic,
                _.map(file.tags, (tag) => tag.id),
              ),
            );
            break;
        }

        dispatch(removePhotoFromUploader(file.id));

        if (!response) {
          throw new AxiosError();
        }

        if (file.type === "IMPORTED_GOOGLE_MEDIA" && !response.success) {
          throw new AxiosError();
        }

        if (file.type === "IMPORTED_GOOGLE_MEDIA" && response.success) {
          return;
        }

        const newPhoto = {
          created_at: response?.created_at,
          description: response?.description,
          id: response?.id,
          latitude: response?.latitude,
          longitude: response?.longitude,
          full: response?.full,
          preview: response?.preview,
          thumbnail: response?.thumbnail,
          title: response?.title,
          files: response?.files,
          type: response?.type,
        };

        dispatch(addPhoto({ newPhoto, user }));
        dispatch(addPhotoToMap({ newPhoto, user }));

        if (!isPublic) {
          await dispatch(
            sharingMediaByGroupIds(
              response.id,
              _.map(file.privacyGroups, (group) => group.id),
            ),
          );
        }
      } catch (error: any) {
        console.error("Error while [onUploadFile]", error);
        throw new AxiosError(error);
      }
    },
    [dispatch, user],
  );

  const uploadFilesSequentially = useCallback(
    async (selectedFiles: IPreparedFileInfo[]) => {
      await _.reduce(
        selectedFiles,
        async (previousPromise, file) => {
          await previousPromise;
          return onUploadFile(file);
        },
        Promise.resolve(),
      );
    },
    [onUploadFile],
  );

  const onShare = useCallback(async () => {
    if (!files) {
      return;
    }

    const { selectedFiles } = files;

    const isGoogleMediaIsUploaded = Boolean(
      _.find(selectedFiles, { type: "IMPORTED_GOOGLE_MEDIA" }),
    );

    try {
      if (Boolean(selectedFilesWithoutLocation)) {
        toast.error(t("upload_photo.photo_not_have_location"));
        return;
      }

      if (isGoogleMediaIsUploaded) {
        dispatch(setLoading(true));
      }

      if (!isGoogleMediaIsUploaded) {
        onClose();
      }

      await uploadFilesSequentially(selectedFiles);

      if (isGoogleMediaIsUploaded) {
        onClose();
      }

      if (isGoogleMediaIsUploaded) {
        toast.success(t("add_photo.google_media_upload_warning"));
      }
    } catch (error: any) {
      console.error("Error while [onShare]", error);
      dispatch(setError(getResponseFromAxiosError(error)));
      onClose();
    } finally {
      if (isGoogleMediaIsUploaded) {
        dispatch(setLoading(false));
      }
    }
  }, [
    files,
    selectedFilesWithoutLocation,
    onClose,
    uploadFilesSequentially,
    toast,
    t,
    dispatch,
  ]);

  useEffect(() => {
    const getTagsEffect = async () => {
      try {
        const response = await dispatch(getTags("", true));

        if (!response) {
          throw new Error();
        }

        dispatch(setTags(response));
      } catch (error) {
        console.error("Error while [getTagsEffect]", error);
      }
    };

    getTagsEffect();
  }, [dispatch]);

  const onAddNewTag = useCallback(
    (tag: ITags) => {
      if (!files) {
        return;
      }

      const isTagAdded = _.find(
        tagsFromSelectedFiles,
        (item) => item.id === tag.id,
      );

      if (isTagAdded) {
        dispatch(removeTagsFromSelectedFiles(tag));
        return;
      }

      dispatch(setTagsInSelectedFiles(tag));
    },
    [dispatch, files, tagsFromSelectedFiles],
  );

  const onAddOrRemoveGroup = useCallback(
    (group: PrivacyGroup, isSelected?: boolean) => {
      if (!files) {
        return;
      }

      if (!isSelected && group.isAll) {
        dispatch(resetPrivacyGroupInSelectedFiles());
      }

      if (isSelected) {
        if (group.isAll) {
          return;
        }

        dispatch(removePrivacyGroupFromSelectedFiles(group));
        return;
      }

      if (isAllGroupSelected) {
        dispatch(removePrivacyGroupFromSelectedFiles(ALL_GROUP));
      }

      dispatch(setPrivacyGroupInSelectedFiles(group));
    },
    [dispatch, files, isAllGroupSelected],
  );

  const onSelectFile = useCallback(
    (file: IPreparedFileInfo, isSelected: boolean) => {
      setSelectedImageType("CUSTOM");

      if (isSelected) {
        dispatch(removeSelectedFile(file));
        return;
      }
      dispatch(selectFiles([file]));
    },
    [dispatch],
  );

  const onSelectAllFiles = useCallback(() => {
    if (selectedImageType === "ALL") {
      setSelectedImageType("CUSTOM");
      dispatch(resetSelectedFiles());
      return;
    }

    setSelectedImageType("ALL");
    dispatch(resetSelectedFiles());

    const sortedFilesInfo = _.sortBy(files?.filesInfo, "id");

    dispatch(selectFiles(sortedFilesInfo));
  }, [dispatch, files?.filesInfo, selectedImageType]);

  const onSelectFilesWithGeo = useCallback(() => {
    if (selectedImageType === "GEO") {
      setSelectedImageType("CUSTOM");
      dispatch(resetSelectedFiles());
      return;
    }
    setSelectedImageType("GEO");
    dispatch(resetSelectedFiles());

    const filesWithGeo = _.filter(files?.filesInfo, (files) =>
      Boolean(files.latitude || files.longitude),
    );

    const sortedFilesInfo = _.sortBy(filesWithGeo, "id");

    dispatch(selectFiles(sortedFilesInfo));
  }, [dispatch, files?.filesInfo, selectedImageType]);

  const onSelectFilesNoGeo = useCallback(() => {
    if (selectedImageType === "NO_GEO") {
      setSelectedImageType("CUSTOM");
      dispatch(resetSelectedFiles());
      return;
    }

    setSelectedImageType("NO_GEO");

    dispatch(resetSelectedFiles());

    const filesNoGeo = _.filter(
      files?.filesInfo,
      (files) => !Boolean(files.latitude || files.longitude),
    );

    dispatch(selectFiles(filesNoGeo));
  }, [dispatch, files?.filesInfo, selectedImageType]);

  const onOpenOrCloseMobileSettingsForm = useCallback(
    () => setIsOpenMobileSettingsForm(!isOpenMobileSettingsForm),
    [isOpenMobileSettingsForm],
  );

  const onTagSearchChange = useCallback((event: any) => {
    const { value } = event.target;

    setTagsSearch(value);
  }, []);

  const onOpenVideoEditor = useCallback(() => {
    if (!files) {
      return;
    }

    dispatch(setVideo(_.last(files.selectedFiles)));
    setIsOpenVideoEditor(true);
  }, [dispatch, files]);

  const onCloseVideoEditor = useCallback(() => {
    setIsOpenVideoEditor(false);
  }, []);

  const onChangePrivacySettings = useCallback(
    (_event: any, value: boolean) => {
      if (value) {
        dispatch(setPrivacyGroupInSelectedFiles(ALL_GROUP));
        return;
      }
      dispatch(removePrivacyGroupFromSelectedFiles(ALL_GROUP));
    },
    [dispatch],
  );

  if (!files) {
    return null;
  }

  return (
    <>
      <Grid sx={materialStyles.innerUploadModalBox}>
        <UploadFileHeader
          title={t("upload_photo.share_photos_value", {
            value: _.size(files.filesInfo),
          })}
          onBack={onBack}
          onNext={onShare}
          nextButtonTitle={t("upload_photo.share")}
          disadledNextButton={_.isEmpty(files.selectedFiles)}
        />
        <Grid sx={materialStyles.uploadPhotoContent}>
          <Grid sx={materialStyles.shareContent}>
            <Grid
              sx={[
                materialStyles.sharePhotosContainer,
                {
                  display: {
                    xs: !isOpenMobileSettingsForm ? "flex" : "none",
                    sm: !isOpenMobileSettingsForm ? "flex" : "none",
                    md: "flex",
                  },
                },
              ]}
            >
              {!isEmptySelectedFiles ? (
                <IconButton
                  onClick={onOpenOrCloseMobileSettingsForm}
                  sx={materialStyles.mobileSettingsPhotoButton}
                >
                  <TuneOutlinedIcon />
                </IconButton>
              ) : null}
              {isShowImageCover ? (
                <Image
                  id="shareContent"
                  style={styles.sharedFile}
                  src={_.last(files.selectedFiles)?.url || files.coverFile?.url}
                  alt="uploaded"
                />
              ) : null}
              {isShowVideoCover ? (
                <Video
                  autoPlay={false}
                  muted
                  id="shareContent"
                  style={styles.sharedFile}
                  src={_.last(files.selectedFiles)?.url || files.coverFile?.url}
                  controls
                  playsInline
                />
              ) : null}
              {isShowYouTubeCover ? (
                <YouTubeIFrame
                  id="shareContent"
                  sx={materialStyles.sharedIframe}
                  videoId={getYouTubeVideoIdFromUrl(
                    _.last(files.selectedFiles)?.url || files.coverFile?.url,
                  )}
                />
              ) : null}
              {isShowVideoCoverWithoutPlayer ? (
                <Grid sx={materialStyles.emptyCoverContainer}>
                  <VideocamIcon sx={materialStyles.videoCamEmptyIcon} />
                  <Typography sx={materialStyles.emptyCoverContainerTitle}>
                    {t("photos.video_preview_not_available", {
                      fileName:
                        _.last(files.selectedFiles)?.fileName ||
                        files.coverFile?.fileName,
                    })}
                  </Typography>
                </Grid>
              ) : null}
              <Box sx={materialStyles.mediaToolbarConrainer}>
                <Box sx={materialStyles.selectOrDeleselectContainer}>
                  <Typography sx={materialStyles.selectOrDeleselectTitle}>
                    {t("upload_photo.select_or_deselect")}
                  </Typography>
                  <Box sx={materialStyles.selectOrDeleselectButtonsContainer}>
                    <ButtonBase
                      sx={[
                        materialStyles.selectOrDeselectButton,
                        selectedImageType === "ALL" &&
                          materialStyles.selectOrDeselectButtonActive,
                      ]}
                      onClick={onSelectAllFiles}
                    >
                      {t("upload_photo.all")}
                    </ButtonBase>
                    <ButtonBase
                      sx={[
                        materialStyles.selectOrDeselectButton,
                        materialStyles.selectOrDeselectButtonWithGeo,
                        selectedImageType === "GEO" &&
                          materialStyles.selectOrDeselectButtonActive,
                      ]}
                      onClick={onSelectFilesWithGeo}
                    >
                      {t("upload_photo.with_geo")}
                    </ButtonBase>
                    <ButtonBase
                      sx={[
                        materialStyles.selectOrDeselectButton,
                        materialStyles.selectOrDeselectButtonNoGeo,
                        selectedImageType === "NO_GEO" &&
                          materialStyles.selectOrDeselectButtonActive,
                      ]}
                      onClick={onSelectFilesNoGeo}
                    >
                      {t("upload_photo.no_geo")}
                    </ButtonBase>
                  </Box>
                </Box>
                {isAvailableVideoEditor ? (
                  <Button
                    sx={materialStyles.editVideoButton}
                    disabled={_.isEmpty(files.selectedFiles)}
                    onClick={onOpenVideoEditor}
                  >
                    {t("add_photo.edit_video")}
                  </Button>
                ) : null}
              </Box>
              <Box
                sx={[
                  materialStyles.sharePhotosListContainer,
                  isEmptySelectedFiles &&
                    materialStyles.sharePhotosListContainerX8,
                ]}
              >
                {_.chain(files.filesInfo)
                  .sortBy("id")
                  .map((file) => (
                    <SharedImageItem
                      key={file.id}
                      file={file}
                      isSelected={Boolean(
                        _.find(files.selectedFiles, { id: file.id }),
                      )}
                      onClick={onSelectFile}
                    />
                  ))
                  .value()}
              </Box>
            </Grid>
            <Grid
              sx={[
                materialStyles.shareFormBlock,
                {
                  overflow: isEmptySelectedFiles ? "hidden" : "auto",
                  padding: isEmptySelectedFiles ? 0 : "0px 20px 20px",
                  flex: isEmptySelectedFiles ? 0 : 1,
                  transition: "0.5s",
                  display: {
                    xs: isOpenMobileSettingsForm ? "flex" : "none",
                    sm: isOpenMobileSettingsForm ? "flex" : "none",
                    md: "flex",
                  },
                },
              ]}
            >
              <Grid sx={materialStyles.userBlock}>
                <UserAvatar user={user} type="PHOTO_PAGE" />
                <Typography sx={materialStyles.userName}>
                  {user?.username || "UserName"}
                </Typography>
              </Grid>
              <TextField
                InputProps={{
                  rows: 4,
                  multiline: true,
                  inputComponent: "textarea",
                }}
                sx={materialStyles.captionInput}
                placeholder={t("upload_photo.write_a_caption")}
                onChange={onChangeDescription}
                value={_.first(files.selectedFiles)?.description}
              />
              <Button
                onClick={onOpenAddLocation}
                sx={materialStyles.specifyLocationButton}
                variant="contained"
                disabled={isDisabledSetFilesLocation}
              >
                {t("upload_photo.location")}
              </Button>
              {isGoogleImportedFileSelected ? (
                <Grid sx={materialStyles.googleImportIsPublicSwitcherContainer}>
                  <ButtonWithIcon
                    checked={Boolean(
                      _.find(privacyGroupsFromSelectedFiles, { isAll: true }),
                    )}
                    title={t("upload_photo.is_public_or_private_setting_title")}
                    subtitle={t(
                      "upload_photo.is_public_or_private_setting_subtitle",
                    )}
                    onClickSwitch={onChangePrivacySettings}
                    actionType="SWITCHER"
                  />
                </Grid>
              ) : null}
              {!isGoogleImportedFileSelected ? (
                <GroupsList
                  title={t(
                    "upload_photo.select_group_that_will_have_access_selected_media",
                  )}
                  groups={groups}
                  selectedGroups={privacyGroupsFromSelectedFiles}
                  onGroupClick={onAddOrRemoveGroup}
                />
              ) : null}
              <Grid sx={materialStyles.tagsList}>
                <Typography sx={materialStyles.tagsListTitle}>
                  {t("upload_photo.you_can_specify_tags")}
                </Typography>
                <AppAutocomplete
                  getOptionLabel={(option) => option.title}
                  noOptionsText={
                    debouncedTagsSearch.length >= DISPLAY_TAGS_FROM_LENGTH
                      ? t("filters.unable_find_tag")
                      : t("filters.to_search_enter_more_than")
                  }
                  options={findedTags}
                  loading={loadingSearchTags}
                  sx={materialStyles.tagsSearchAutocomplete}
                  renderInput={(params) => (
                    <FiltersAutocompleteInput
                      {...params}
                      placeholder={t("filters.enter_tag_name")}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: null,
                      }}
                      onChange={onTagSearchChange}
                    />
                  )}
                  renderOption={(props, option) => (
                    <AutocompleteOptionWithTitle
                      option={option}
                      onOptionClick={() => onAddNewTag(option)}
                      {...props}
                    />
                  )}
                />
                <TagsList
                  tags={selectedFilesTags}
                  choosenTags={tagsFromSelectedFiles}
                  onTagClick={onAddNewTag}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddLocationForFileModal
        isOpen={isOpenAddLocation}
        onClose={onCloseAddLocation}
      />
      <VideoEditorModal
        isOpen={isOpenVideoEditor}
        onClose={onCloseVideoEditor}
      />
      <LoaderWithBackdrop isShow={loading} />
    </>
  );
};
