import React, { useMemo } from "react";
import { MaterialIFrame } from "./MaterialIFrame";
import { getYouTubeVideoEmbedFromId } from "../../utils/youtube";
import { IMaterialStyleItem } from "../../types";

type IYouTubeIFrame = {
  videoId?: string;
  style?: React.CSSProperties;
  sx?: IMaterialStyleItem | IMaterialStyleItem[];
  id?: string;
  onLoad?: () => void;
};

export const YouTubeIFrame: React.FC<IYouTubeIFrame> = ({
  videoId,
  style,
  id,
  sx,
  onLoad,
}) => {
  const embedUrl = useMemo(
    () => getYouTubeVideoEmbedFromId(videoId),
    [videoId],
  );

  return (
    <MaterialIFrame
      sx={sx}
      style={style}
      id={id}
      src={embedUrl}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
      frameBorder="0"
      title="YouTube video player"
      onLoad={() => {
        onLoad && onLoad();
      }}
    />
  );
};
