import { Box, Modal, Grid, Typography, Button } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { materialStyles } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setLocationToSelectedFiles,
  TPrepareFileType,
} from "../../../store/slices/uploadFile";
import { IMapMarker, MapComponent } from "../../map/MapComponent";
import {
  INITIAL_MAP_LATITUDE,
  INITIAL_MAP_LONGITUDE,
} from "../../../constants";
import { editMediaById } from "../../../store/thunks/media";
import { changeUserPhotoLocation } from "../../../store/slices/userPhoto";

type ChooseCustomLocation = {
  longitude: number;
  latitude: number;
};

type IAddLocationForFileModal = {
  isOpen: boolean;
  onClose: () => void;
  type?: "ADD_OR_CHANGE_LOCATION_FOR_UPLOAD_FILE" | "CHANGE_LOCATION_FOR_MEDIA";
};

export const AddLocationForFileModal: React.FC<IAddLocationForFileModal> = ({
  isOpen,
  onClose: onCloseFromProps,
  type = "ADD_OR_CHANGE_LOCATION_FOR_UPLOAD_FILE",
}) => {
  const { t } = useTranslation();

  const { files } = useAppSelector((state) => state.uploadFile);
  const { photo } = useAppSelector((state) => state.userPhoto);

  const dispatch = useAppDispatch();

  const [choosenLocation, setChoosenLocation] =
    useState<ChooseCustomLocation | null>(null);

  const selectedMarkers = useMemo(() => {
    let markers;

    switch (type) {
      case "ADD_OR_CHANGE_LOCATION_FOR_UPLOAD_FILE":
        if (!files) {
          return;
        }

        markers = _.map(files.selectedFiles, (file) =>
          choosenLocation || Boolean(file.latitude && file.longitude)
            ? {
                url: file.url,
                latitude: file.isHaveLocationByDefault
                  ? file.latitude
                  : choosenLocation?.latitude || file.latitude,
                longitude: file.isHaveLocationByDefault
                  ? file.longitude
                  : choosenLocation?.longitude || file.longitude,
                fileType: file.type,
              }
            : undefined,
        );
        break;
      case "CHANGE_LOCATION_FOR_MEDIA":
        if (!photo) {
          return;
        }

        markers = [
          choosenLocation || photo
            ? {
                url: photo.thumbnail,
                latitude: choosenLocation
                  ? choosenLocation.latitude
                  : photo.latitude,
                longitude: choosenLocation
                  ? choosenLocation.longitude
                  : photo.longitude,
                fileType: "IMAGE" as TPrepareFileType,
              }
            : undefined,
        ];
        break;
    }

    return _.compact(markers) as IMapMarker[];
  }, [choosenLocation, files, photo, type]);

  const onChooseLocation = useCallback((lng: number, lat: number) => {
    setChoosenLocation({ latitude: lat, longitude: lng });
  }, []);

  const onClose = useCallback(() => {
    setChoosenLocation(null);
    onCloseFromProps();
  }, [onCloseFromProps]);

  const onSaveLocation = useCallback(async () => {
    if (!choosenLocation) {
      onClose();
      return;
    }

    switch (type) {
      case "ADD_OR_CHANGE_LOCATION_FOR_UPLOAD_FILE":
        dispatch(setLocationToSelectedFiles(choosenLocation));
        break;
      case "CHANGE_LOCATION_FOR_MEDIA":
        if (!photo) {
          break;
        }

        await dispatch(
          editMediaById(photo.id, {
            latitude: choosenLocation.latitude,
            longitude: choosenLocation.longitude,
          }),
        );

        dispatch(
          changeUserPhotoLocation({
            latitude: choosenLocation.latitude,
            longitude: choosenLocation.longitude,
          }),
        );

        break;
    }

    onClose();
  }, [choosenLocation, dispatch, onClose, photo, type]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={materialStyles.addLocationModal}>
      <Box sx={materialStyles.addLocationBox}>
        <Grid sx={materialStyles.addLocationModalInnerContainer}>
          <Grid sx={materialStyles.modalHeaderBlock}>
            <Typography sx={materialStyles.modalHeaderTitle}>
              {t("upload_photo.add_or_change_location")}
            </Typography>
            <Typography sx={materialStyles.modalHeaderSubtitle}>
              {t("upload_photo.select_map_where_took_photo")}
            </Typography>
          </Grid>
          <Grid sx={materialStyles.itemsContainer}>
            <MapComponent
              containerStyle={{
                borderRadius: "8px",
                width: "100%",
                height: "400px",
              }}
              zoom={3}
              onMapClick={onChooseLocation}
              initialLatitude={_.get(
                choosenLocation,
                "latitude",
                INITIAL_MAP_LATITUDE,
              )}
              initialLongitude={_.get(
                choosenLocation,
                "longitude",
                INITIAL_MAP_LONGITUDE,
              )}
              markers={selectedMarkers}
            />
          </Grid>
          <Button
            type="submit"
            sx={materialStyles.saveButton}
            variant="contained"
            onClick={onSaveLocation}
          >
            {t("common.save")}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
