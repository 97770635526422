import React, { useCallback, useMemo, useState } from "react";
import { IUploadPhotoModalType } from "..";
import { Grid, TextField, Typography } from "@mui/material";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { UploadFileHeader } from "./UploadFileHeader";
import _ from "lodash";
import { useAppDispatch } from "../../../../store";
import {
  IPreparedFileInfo,
  setFile,
} from "../../../../store/slices/uploadFile";
import { ALL_GROUP } from "../../../../hooks/usePrivacyGroups";
import { getYouTubeVideoIdFromUrl } from "../../../../utils/youtube";

type TYouTubeVideoEmbedForm = {
  onChangeUploadModalType: (type: IUploadPhotoModalType) => void;
};

export const YouTubeVideoEmbedForm: React.FC<TYouTubeVideoEmbedForm> = ({
  onChangeUploadModalType,
}) => {
  const [youTubeLink, setYouTubeLink] = useState("");
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const disabledNext = useMemo(
    () =>
      _.isEmpty(getYouTubeVideoIdFromUrl(_.chain(youTubeLink).trim().value())),
    [youTubeLink],
  );

  const onBack = useCallback(
    () => onChangeUploadModalType("UPLOAD_NEW_PHOTO"),
    [onChangeUploadModalType],
  );

  const onNext = useCallback(() => {
    const prepareForUploadYouTubeVideo: IPreparedFileInfo = {
      id: _.uniqueId(),
      url: youTubeLink,
      isHaveLocation: false,
      isHaveLocationByDefault: false,
      longitude: undefined,
      latitude: undefined,
      privacyGroups: [ALL_GROUP],
      tags: [],
      description: "",
      type: "YOUTUBE_VIDEO",
      originalFile: null,
    };

    dispatch(
      setFile({
        filesInfo: [prepareForUploadYouTubeVideo],
        selectedFiles: [prepareForUploadYouTubeVideo],
        coverFile: prepareForUploadYouTubeVideo,
        metaData: undefined,
      }),
    );

    onChangeUploadModalType("SHARE_UPLOADED_FILE");
  }, [dispatch, onChangeUploadModalType, youTubeLink]);

  const onChangeYouTubeLink = useCallback((event: any) => {
    const { value } = event.target;

    setYouTubeLink(value);
  }, []);

  return (
    <Grid sx={materialStyles.innerYouTubeUploadModalBox}>
      <UploadFileHeader
        title={t("upload_photo.embed_youtube_video")}
        onBack={onBack}
        onNext={onNext}
        nextButtonType="SECONDARY"
        disadledNextButton={disabledNext}
      />
      <Grid sx={materialStyles.embedVideoFormContainer}>
        <Typography sx={materialStyles.embedYoutubeVideoText}>
          {t("upload_photo.embed_youtube_video_text")}
        </Typography>
        <TextField
          variant="filled"
          sx={materialStyles.youTubeLinkInput}
          fullWidth
          placeholder={t("upload_photo.enter_youtube_link")}
          value={youTubeLink}
          onChange={onChangeYouTubeLink}
        />
      </Grid>
    </Grid>
  );
};
