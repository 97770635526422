import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { materialStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { UploadFileHeader } from "./UploadFileHeader";
import { IUploadPhotoModalType } from "..";
import { useFetchMediaFromGoogleAccount } from "../../../../hooks/useFetchMediaFromGoogleAccount";
import { AppImageList } from "../../../lists/imageList/AppImageList";
import _ from "lodash";
import {
  IPreparedFileInfo,
  setFile,
} from "../../../../store/slices/uploadFile";
import { ALL_GROUP } from "../../../../hooks/usePrivacyGroups";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { SocialButtons } from "../../authModal/components/SocialButtons";

type TSelectedGooglePhotoForm = {
  onChangeUploadModalType: (type: IUploadPhotoModalType) => void;
};

export const SelectedGooglePhotoForm: React.FC<TSelectedGooglePhotoForm> = ({
  onChangeUploadModalType,
}) => {
  const { token } = useAppSelector((state) => state.user);
  const [selectedMediaIds, setSelectedMediaIds] = useState<number[]>([]);
  const dispatch = useAppDispatch();

  const layoutRef = useRef<any>(null);

  const { t } = useTranslation();

  const {
    media,
    isGooglePhotoNoDataError,
    isGooglePhotoRefreshTokenError,
    onUpdatePageToken,
  } = useFetchMediaFromGoogleAccount();

  const onBack = useCallback(
    () => onChangeUploadModalType("UPLOAD_NEW_PHOTO"),
    [onChangeUploadModalType],
  );

  const onNext = useCallback(() => {
    const filteredMedia = _.filter(media, (item) =>
      _.includes(selectedMediaIds, item.id),
    );

    const preparedFilteredMedia: IPreparedFileInfo[] = _.map(
      filteredMedia,
      (item) => ({
        id: _.toString(item.id),
        url: item.preview,
        isHaveLocation: false,
        isHaveLocationByDefault: false,
        longitude: undefined,
        latitude: undefined,
        privacyGroups: [ALL_GROUP],
        tags: [],
        description: "",
        type: "IMPORTED_GOOGLE_MEDIA",
        ext: undefined,
        fileName: item.title,
        size: undefined,
        originalFile: null,
      }),
    );

    dispatch(
      setFile({
        filesInfo: preparedFilteredMedia,
        selectedFiles: preparedFilteredMedia,
        coverFile: _.first(preparedFilteredMedia),
        metaData: undefined,
      }),
    );

    onChangeUploadModalType("SHARE_UPLOADED_FILE");
  }, [dispatch, media, onChangeUploadModalType, selectedMediaIds]);

  const onSelected = useCallback(
    (ids: number[]) => setSelectedMediaIds(ids),
    [],
  );

  useEffect(() => {
    const gridElement = layoutRef.current;

    const handleScroll = () => {
      if (
        Math.round(gridElement.scrollTop + gridElement.clientHeight) >=
          gridElement.scrollHeight - 10 ||
        Math.floor(gridElement.scrollTop + gridElement.clientHeight) >=
          gridElement.scrollHeight - 10
      ) {
        onUpdatePageToken();
      }
    };

    gridElement.addEventListener("scroll", handleScroll);

    return () => {
      gridElement.removeEventListener("scroll", handleScroll);
    };
  }, [onUpdatePageToken]);

  return (
    <Grid sx={materialStyles.innerUploadModalBox}>
      <UploadFileHeader
        title={t("upload_photo.import_google_photo")}
        onBack={onBack}
        onNext={onNext}
        nextButtonType="SECONDARY"
        disadledNextButton={_.isEmpty(selectedMediaIds)}
      />
      {!isGooglePhotoNoDataError && !isGooglePhotoRefreshTokenError ? (
        <Grid ref={layoutRef} sx={materialStyles.googleImportFormContainer}>
          <Typography sx={materialStyles.googleImportText}>
            {t("upload_photo.import_google_photo_text")}
          </Typography>
          <AppImageList
            photos={media}
            enableSelection
            enableFavorites={false}
            onSelected={onSelected}
            imageItemStyle={materialStyles.googleImportFormItem}
            objectFit="contain"
          />
        </Grid>
      ) : null}
      {isGooglePhotoNoDataError ? (
        <Grid sx={materialStyles.googleImportErrorFormContainer}>
          <Typography sx={materialStyles.googleImportText}>
            {t("upload_photo.import_google_photo_no_data_error")}
          </Typography>
        </Grid>
      ) : null}
      {isGooglePhotoRefreshTokenError ? (
        <Grid sx={materialStyles.googleImportErrorFormContainer}>
          <Typography sx={materialStyles.googleImportText}>
            {t("upload_photo.import_google_photo_no_refresh_token_error")}
          </Typography>
          <SocialButtons type="CONNECT_GOOGLE_ACCOUNT" state={token} />
        </Grid>
      ) : null}
    </Grid>
  );
};
